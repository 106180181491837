<template>
    <router-view :key="$route.path"/>
    <div id="Dialogs"></div>
	<LoadingScreen ref="loadingRef"/>
    <template v-for="item of dynamicComponents" :key="item.id">
        <component :ref="() => !item.$_isLoaded && resolveCallback( item )" :is="item.component" v-bind="item.properties" v-on="item.listeners"/>
    </template>
</template>

<script setup>
    import { getCurrentInstance, defineAsyncComponent, shallowRef, ref, nextTick } from "vue";
    import { useRouter } from 'vue-router';
	import LoadingScreen from './components/LoadingScreen';

	const loadingRef = ref( null );
	const router = useRouter();

	router.isReady().then( () =>
	{
		loadingRef.value.hidden()
	} );

    const app               = getCurrentInstance();
    const dynamicComponents = ref ( [] );

    const resolveCallback = async ( item ) =>
    {
        if( typeof item.callback === 'function' )
        {
            await nextTick();
            item.callback();
        }

        item.$_isLoaded = true;
    }

    app.appContext.config.globalProperties.$dynamicComponent =
    {
        load: ( id, component, props, listeners, callback ) =>
        {
            dynamicComponents.value.push(
                {
                    id,
                    properties: ( typeof props === 'function' ? {} : props ),
                    listeners: ( typeof listeners  === 'function' ? {} : listeners ?? {} ),
                    callback: ( typeof props === 'function' ? props : ( typeof listeners === 'function' ? listeners : callback ) ),
                    component: shallowRef( defineAsyncComponent( async () => await component() ) ),
                    $_isLoaded : false
                } );
        },
        destroy: async ( id ) =>
        {
            if( dynamicComponents.value.length )
            {
                await nextTick();
                dynamicComponents.value = dynamicComponents.value.filter( item => item.id !== id );
            }
        }
    };
</script>

<style lang="scss">
	.text-right
	{
		text-align: right;
	}

	.text-left
	{
		text-align: left;
	}

	.text-right-i
	{
		text-align: right!important;
	}

	.text-left-i
	{
		text-align: left!important;
	}

    .v-popper--theme-tooltip, .v-popper--theme-menu
    {
        .v-popper__inner
        {
            background: white;
            padding: px(12);
            border-radius: 0;
            border: 1px solid var(--gray-3);
            color: var(--gray-6);
        }

        .v-popper__arrow-outer
        {
            border-color: var(--gray-3);
        }

        .v-popper__arrow-inner
        {
            border-color: white;
            visibility: visible!important;
        }
    }

    .v-popper--theme-input-hint-tooltip
    {
        .v-popper__inner
        {
            //margin-top: calc( #{fpx(17)} + #{px(2)} );
            //min-height: var(--input-regular-height);

            @include font-s;
            @include font-regular;
            display: flex;
            align-items: center;
            padding: px(6);
            background-color: rgb(0 86 143 / 90%);
            border-color: var(--brand-blue-5);
            color: white;
        }

        .v-popper__arrow-container
        {
            //margin-top: calc( ( #{fpx(17)} + #{px(2)} ) / 2 );

            .v-popper__arrow-outer
            {
                border-color: var(--brand-blue-5);
            }

            .v-popper__arrow-inner
            {
                visibility: hidden!important;
            }
        }
    }

    .v-popper--theme-dropdown
    {
        .v-popper
        {
            cursor: pointer;
        }

        .v-popper__inner
        {
            border-radius: 0!important;
            border: 1px solid var(--gray-3)!important;
        }
    }

	.Table-content-wrap
	{
		tr
		{
			position: relative;

			td
			{
				.table-line
				{
					position: absolute;
					height: 4px;
					background: var(--brand-light-blue-1);
					bottom: 0;
					left: 0;
					width: var(--width);
				}
			}
		}
	}

</style>
