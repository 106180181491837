function QuerystringExtend( data, querystring = [], prefix = '' )
{
    if( data === null )
    {
        querystring.push( prefix );
    }
    else if( typeof data === 'boolean' )
    {
        querystring.push( prefix + '=' + ( data ? '1' : '0' ));
    }
    else if( typeof data === 'number' || typeof data === 'string' )
    {
        querystring.push( prefix + '=' + encodeURIComponent( data.toString() ));
    }
    else if( Array.isArray( data ))
    {
        for( let i = 0; i < data.length; ++i )
        {
            QuerystringExtend( data[i], querystring, prefix + '[' + i + ']' );
        }
    }
    else if( typeof data === 'object' )
    {
        for( let key in data )
        {
            QuerystringExtend( data[key], querystring, prefix ? prefix + '[' + key + ']' : key );
        }
    }

    return querystring;
}

function Querystring( data )
{
    return QuerystringExtend( data ).join('&');
}

class ApiService
{
    #ctx;

    constructor( ctx )
    {
        this.#ctx = { ...ctx };
    }

    get      ( url, options ){ return this.request( url, { method: 'GET', ...options } )}
    post     ( url, options ){ return this.request( url, { method: 'POST', ...options } )}
    put      ( url, options ){ return this.request( url, { method: 'PUT', ...options } )}
    patch    ( url, options ){ return this.request( url, { method: 'PATCH', ...options } )}
    delete   ( url, options ){ return this.request( url, { method: 'DELETE', ...options } )}

    async request( url, options = {} )
    {
        let headers =
        {
            'X-Requested-With'  : 'XMLHttpRequest',
            ...options.headers
        };

        if( options.query )
        {
            url += '?' + Querystring( options.query );
        }

        if( options.body && typeof options.body !== 'string' && !( options.body instanceof FormData ))
        {
            headers['Content-Type'] = 'application/json';
            options.body = JSON.stringify( options.body );
        }

        return fetch( this.#ctx.url + url,
        {
            method      : options.method || 'GET',
            headers,
            //mode        : 'same-origin',
            credentials : 'include',
            cache       : 'no-cache',
            redirect    : 'follow',
            query       : options.query,
            body        : options.body
        })
        .then( async response =>
        {
            const content = ( response.headers.get( 'Content-Type' ) && response.headers.get( 'Content-Type' ).indexOf('application/json') > -1
                ? await response.json()
                : await response.text() );

            if( response.status < 200 || response.status >= 300 )
            {
                throw { status: response.status, message: content }
            }

            return content;
        } );
    }

}

export const $api =
{
    iam : new ApiService({ url: process.env.VUE_APP_API_IAM_URL }),
    app : new ApiService({ url: process.env.VUE_APP_API_APP_URL })
};