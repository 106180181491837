// https://vuejs.org/guide/reusability/plugins.html#writing-a-plugin

export default
{
    install: ( app, options ) =>
    {
        app.config.globalProperties.$translate = ( key ) =>
        {
            return key.split('.').reduce((o, i) => { if (o) return o[i] }, options );
        }

        app.provide( 'i18n', options )
    }
}