import AuthService from '@/services/auth.service';

export default
{
    namespaced  : true,
    state       : () => (
    {
        isLogged: AuthService.isCookieSet(),
        user    : null
    } ),
    actions     :
    {
        isLogged( { state } )
        {
            return AuthService.isCookieSet(); //Boolean( state.user );
        },

        async getUser( { commit, state, dispatch }, options )
        {
            console.log( 'som tu' )
            if( !state.user && options.requiresAuth )
            {
                const user = await AuthService.getUser()
                .catch( e =>
                {
                    console.log( 'AuthService.getUser:', e )
                    dispatch( 'logout' );
                } );

                commit( 'setUser', user );

                return true;
            }

            return false;
        },

        async logout( { commit } )
        {
            await AuthService.logout();
            commit( 'setUser', null );
            location.href = process.env.VUE_APP_AUTH_URL + '/login';
        }
    },
    mutations:
    {
        setUser( state, user )
        {
            state.isLogged   = AuthService.isCookieSet();//Boolean( user );
            state.user       = ( user === false ? null : user );
        }
    },
    getters:
    {
        user (state)
        {
            return state.user;
        },
        userFullName( state )
        {
            return state.user.firstName + ' ' + state.user.lastName;
        },
        userAvatar( state )
        {
            return '';
        },
        userRoles( state )
        {
            return AuthService.getPermissions();
            //return state.user?.accounts?.[0]?.scopes || [''];
        },
        isLogged( state )
        {
            return state.isLogged;
        }
    }
};